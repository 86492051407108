<template>
    <div class="registration">
        <div class="registration__logo">
            <div class="container container-resize-2">
                <img src="images/logo.png" alt="logo" class="registration__logo--img">
            </div>
        </div>

        <div class="registration__title mt-5">
            <div class="container container-resize-2">
                <h1 class="registration__title--main">Forgot Password</h1>
                <h2 class="registration__title--sub">Enter you email to recover password.</h2>
            </div>
        </div>

        <div class="registration__detail mt-5">
            <div class="container container-resize-2">
                <h1>Forgot Password</h1>
            </div>
        </div>

        <div class="container container-resize-2 mt-5">
            <div class="registration__text">
                <p>Forgot you password</p>
            </div>
          <ValidationObserver ref="forgot" v-slot="{handleSubmit}">
            <form @submit.prevent="handleSubmit(SubmitForm)">
              <div class="row mt-5">
                <div class="col-md-6 offset-md-3">
                  <div class="form-group">
                    <label for="reg" class="form__label">Password Recovery</label>
                    <ValidationProvider
                        name="email"
                        rules="required|email"
                        v-slot="{valid, errors }"
                    >
                      <input type="email" class="form-control form__input margin-bottom"
                             v-model.trim="email"
                             placeholder="Recovery email" id="reg">
                      <div class="errors">{{errors[0]}}</div>
                    </ValidationProvider>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-4 offset-md-4">
                  <button type="submit" class="btn__registration mt-0 mb-5">Submit</button>
                </div>
              </div>
            </form>
          </ValidationObserver>
        </div>
    </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
  name: "ForgotPassword",
  data () {
    return {
      email: ''
    }
  },
  methods: {
    ...mapActions({
      ForgotPasswordEmail: 'auth/ForgotPasswordEmail'
    }),
    async SubmitForm () {
      const isValid = this.$refs.forgot.validate()
      if (!isValid){
        this.$toastr.w('Input not entered properly')
        return false
      }

      try {
        const res = await this.ForgotPasswordEmail({email: this.email})
        if (res.data) {
          this.$toastr.s(this.message)
          this.$router.push('/')
        }
      } catch (e) {
        this.$toastr.e(this.errors)
      }
    },
  },
  computed: {
    ...mapGetters({
      errors: 'setup/errors',
      message: 'setup/message',
    })
  }
}
</script>

<style scoped>

</style>
