<template>
  <ValidationObserver ref="reset" v-slot="{handleSubmit}">
    <form @submit.prevent="handleSubmit(SubmitForm)">
      <div class="row mt-5">
        <div class="col-md-6 offset-md-3">
          <div class="form-group">
            <label for="reg" class="form__label">New Password</label>
            <ValidationProvider
                name="password"
                rules="required|min:8|max:32"
                v-slot="{valid, errors }"
            >
              <input type="password" class="form-control form__input "
                     name="password"
                     v-model.trim="password.password"
                     placeholder="New Password" id="reg">
              <div class="errors">{{errors[0]}}</div>
            </ValidationProvider>
          </div>
        </div>
      </div>
      <div class="col-md-6 offset-md-3">
        <div class="form-group">
          <label for="dd" class="form__label">Confirm Password</label>
          <ValidationProvider
              name="confirm_password"
              rules="required|confirmed:password"
              v-slot="{valid, errors }"
          >
            <input type="password" class="form-control form__input "
                   name="confirm_password"
                   v-model.trim="password.confirm_password"
                   placeholder="Confirm Password" id="dd">
            <div class="errors">{{errors[0]}}</div>
          </ValidationProvider>
        </div>
      </div>
      <div class="row">
        <div class="col-md-4 offset-md-4">
          <button class="btn__registration mt-2 mb-5" type="submit">Submit</button>
        </div>
      </div>
    </form>
  </ValidationObserver>

</template>

<script>
import {mapGetters, mapActions} from 'vuex'
export default {
  name: "SetPasswordForm",
  data () {
    return {
      password: {},
      email: this.$route.params.email,
      encrypt: this.$route.params.encrypt
    }
  },
  methods: {
    ...mapActions({
      ForgotPasswordSent: 'auth/ForgotPasswordSent'
    }),
    async SubmitForm () {
      const isValid = this.$refs.reset.validate()
      if (!isValid){
        this.$toastr.w('Input not entered properly')
        return false
      }

      const formDate = new FormData
      formDate.append('email', this.email)
      formDate.append('password', this.password.password)
      formDate.append('token', this.encrypt)
      try {
        const res = await this.ForgotPasswordSent(formDate)
        if (res.data) {
          this.$toastr.s(this.message)
          this.$router.push('/')
        }
      } catch (e) {
        this.$toastr.e(this.errors)
      }
    },
  },
  computed: {
    ...mapGetters({
      errors: 'setup/errors',
      message: 'setup/message',
    })
  }
}
</script>

<style scoped>

</style>