<template>
    <div class="registration">
        <div class="registration__logo">
            <div class="container container-resize-2">
                <img src="images/logo.png" alt="logo" class="registration__logo--img">
            </div>
        </div>

        <div class="registration__title mt-5">
            <div class="container container-resize-2">
                <h1 class="registration__title--main">Reset Password</h1>
                <h2 class="registration__title--sub">Set the password now.</h2>
            </div>
        </div>

        <div class="registration__detail mt-5">
            <div class="container container-resize-2">
                <h1>Reset Password</h1>
            </div>
        </div>

        <div class="container container-resize-2 mt-5">
            <div class="registration__text">
                <p>Reset Password</p>
            </div>
            <set-password-form></set-password-form>
        </div>
    </div>
</template>

<script>
    import SetPasswordForm from "./SetPasswordForm";
    export default {
        name: "ResetPassword",
      components: {SetPasswordForm}
    }
</script>

<style scoped>

</style>
