var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{ref:"reset",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.SubmitForm)}}},[_c('div',{staticClass:"row mt-5"},[_c('div',{staticClass:"col-md-6 offset-md-3"},[_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"form__label",attrs:{"for":"reg"}},[_vm._v("New Password")]),_c('ValidationProvider',{attrs:{"name":"password","rules":"required|min:8|max:32"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.password.password),expression:"password.password",modifiers:{"trim":true}}],staticClass:"form-control form__input ",attrs:{"type":"password","name":"password","placeholder":"New Password","id":"reg"},domProps:{"value":(_vm.password.password)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.password, "password", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),_c('div',{staticClass:"errors"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)])]),_c('div',{staticClass:"col-md-6 offset-md-3"},[_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"form__label",attrs:{"for":"dd"}},[_vm._v("Confirm Password")]),_c('ValidationProvider',{attrs:{"name":"confirm_password","rules":"required|confirmed:password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.password.confirm_password),expression:"password.confirm_password",modifiers:{"trim":true}}],staticClass:"form-control form__input ",attrs:{"type":"password","name":"confirm_password","placeholder":"Confirm Password","id":"dd"},domProps:{"value":(_vm.password.confirm_password)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.password, "confirm_password", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),_c('div',{staticClass:"errors"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-4 offset-md-4"},[_c('button',{staticClass:"btn__registration mt-2 mb-5",attrs:{"type":"submit"}},[_vm._v("Submit")])])])])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }